<template>
  <div>
    <Header />
    <BoothList style="margin-top: 32px" />
    <div
      class="container d-flex justify-content-center"
      style="margin-bottom: 32px"
    >
      <!--
      <router-link
        class="btn btn-danger"
        :to="'/live/' + currentEvent.defaultStage"
        >Canlı Yayın İçin Tıklayın</router-link
      >
      -->
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BoothList from '@/components/BoothList.vue';
import { mapGetters } from 'vuex';
// import Hero from '@/components/Hero.vue';

export default {
  components: {
    Header,
    BoothList,
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
};
</script>

<style>
</style>
