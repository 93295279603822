<template>
  <div class="home">
    <Header />
    <Hero />

    <section>
      <div class="container mt-5"></div>
    </section>
    <!--<BoothList />-->
    <!--<Sponsors />-->
    <!--<Expo />-->
    <Agenda />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Hero from '@/components/Hero.vue';
import Agenda from '@/components/Agenda.vue';
// import BoothList from '@/components/BoothList.vue';
// import Sponsors from '@/components/Sponsors.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { activateCertificate } from '@/config';

export default {
  name: 'Home',
  components: {
    Header,
    Agenda,
    // Sponsors,
    Hero,
    // BoothList,
  },
  data() {
    return {
      isReady: false,
      certificateEnabled: false,
      isCertDownloading: false,
    };
  },
  computed: {
    ...mapGetters({
      liveSession: 'agenda/liveSession',
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrent: 'event/setCurrent',
      getEvent: 'event/get',
      getCertificate: 'people/getCertificateUrl',
    }),
    tabClick() {},
    goBack() {
      this.$router.push('/');
    },
    enableCertificate() {
      this.certificateEnabled = false;
      const sesTime = moment();
      const enableTime = moment(activateCertificate, 'DD MM YYYY hh:mm');
      console.log(enableTime);
      console.log(sesTime);

      if (enableTime.isBefore(sesTime)) {
        this.certificateEnabled = true;
      } else {
        this.certificateEnabled = false;
      }
      console.log(this.certificateEnabled);
    },
    downloadCert() {
      this.isCertDownloading = true;
      this.getCertificate().then((res) => {
        this.isCertDownloading = false;
        console.log(res);
      });
    },
  },
  created() {
    this.enableCertificate();
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.live {
    h2 {
      color: #dc3545;
    }
  }
}
</style>
